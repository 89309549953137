import React from 'react';
import { Link } from 'gatsby';
import Nav from 'components/header/nav';
import styled from 'styled-components';

const StyledAnimatedContainerCss = styled.div`
  position: sticky;
  top: -1px;
  padding: 0 50px;
  margin: 0 auto;
  max-width: 1300px;
  z-index: 200;
  background: ${props => props.theme.backgroundBlured};
  /* transition: background-color 300ms ease 0s; */
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    padding: 0;
  }
`;

const ContainerCss = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 32px;

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
    font-size: 22px;
  }
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    padding: 7px 16px;
    justify-content: space-between;
    align-items: space-between;
  }
`;

export const SpacerCss = styled.div`
  height: 30px;
  width: 100%;
`;

const Header = ({ title }) => (
  <>
    <SpacerCss />
    <StyledAnimatedContainerCss>
      <ContainerCss>
        <Link to="/">
          <h1>{title}</h1>
        </Link>
        <Nav />
      </ContainerCss>
    </StyledAnimatedContainerCss>
  </>
);

export default Header;
