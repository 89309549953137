import React from 'react';
import styled from 'styled-components';
import useStore from '../../../store/useStore';
import Sun from '../../../images/svg/sun.svg';
import Moon from '../../../images/svg/moon.svg';

const ToggleCss = styled.div`
  display: block;
  width: 26px;
  height: 26px;
  cursor: pointer;
  svg {
    width: 26px !important;
    height: 26px !important;
    path {
      fill: ${props => props.theme.icons};
    }
  }
  &:hover svg path {
    fill: ${props => props.theme.text};
  }

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    width: 42px;
    height: 42px;
    padding: 0 !important;
    svg {
      width: 42px !important;
      height: 42px !important;
      path {
        fill: ${props => props.theme.text};
      }
    }
  }
`;
const ThemeToggle = () => {
  const { isLightTheme, toggleTheme } = useStore();
  const handleClick = e => {
    e.stopPropagation();
    toggleTheme();
  };

  if (isLightTheme) {
    return (
      <ToggleCss id="toggle" onClick={handleClick}>
        <Sun />
      </ToggleCss>
    );
  }
  return (
    <ToggleCss id="toggle" onClick={handleClick}>
      <Moon />
    </ToggleCss>
  );
};

export default ThemeToggle;
