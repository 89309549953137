import React, { useState } from 'react';
import { Link } from 'gatsby';

import {
  ContainerCss,
  DesktopViewCss,
  MobileViewCss,
  MobileMenuCss,
  ListCss,
} from './nav.css';
import BurgerMenu from './burger';
import ThemeToggle from './themeToggle';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ContainerCss>
      <DesktopViewCss>
        <Menu isOpen={true} />
      </DesktopViewCss>
      <MobileViewCss>
        <BurgerMenu setIsOpen={setIsOpen} isOpen={isOpen} />
        <MobileMenuCss onClick={() => setIsOpen(false)} isOpen={isOpen}>
          <Menu isOpen={isOpen} />
        </MobileMenuCss>
      </MobileViewCss>
    </ContainerCss>
  );
};

const Menu = ({ isOpen }) => {
  return (
    <>
      <ListCss isOpen={isOpen}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/snippets">Snippets</Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
      </ListCss>
      <ListCss isOpen={isOpen}>
        <li>
          <ThemeToggle />
        </li>
      </ListCss>
    </>
  );
};

export default Nav;
