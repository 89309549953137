import styled, { css } from 'styled-components';

export const ContainerCss = styled.nav`
  margin-left: 50px;
  display: flex;
  flex: 1;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    justify-content: flex-end;
  }
`;

export const ListCss = styled.ul`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 13px;

  li {
    margin: 10px;
    a {
      text-decoration: none;
      color: ${props => props.theme.text};
      font-size: 16px;
      padding: 10px;
      font-weight: 400px;
      letter-spacing: 0.3px;
    }
  }
`;

export const DesktopViewCss = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    display: none;
  }
`;
export const MobileViewCss = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    display: block;
  }
`;

export const MobileMenuCss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.backgroundBlured};
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease 0s, background-color 350ms ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    flex-direction: column;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  li {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    transform: translateX(-70%);
    transition: transform 400ms ease 0s;
    a,
    div {
      padding: 15px 20px;
      font-size: 24px;
    }
  }

  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      pointer-events: all;
      li {
        transform: translateX(0%);
      }

      li:nth-child(1) {
        transition-delay: 0;
      }
      li:nth-child(2) {
        transition-delay: 100ms;
      }
      li:nth-child(3) {
        transition-delay: 200ms;
      }
      li:nth-child(4) {
        transition-delay: 300ms;
      }
      li:nth-child(5) {
        transition-delay: 400ms;
      }
      li:nth-child(6) {
        transition-delay: 500ms;
      }
    `}
`;
